import Link from 'next/link'

export function Functions() {
  return (
    <section>
      <div className="mx-auto px-6 pt-10 pb-10 text-center lg:pt-20 lg:pb-20 lg:max-w-5xl">
        <h2 className="text-lg font-bold text-textblack text-center lg:text-4xl font-feature-settings">
          主な機能
        </h2>
        <p className="mt-6 text-base text-darken text-center font-feature-settings">
          様々な機能があなたをサポートします
        </p>

        <div className="mt-6 flex flex-wrap justify-center gap-4 lg:gap-10 lg:mt-14">
          <div className="relative w-full border border-solid border-gray bg-white px-8 py-6 rounded-lg lg:w-[293px] lg:py-10">
            <h3 className="text-base font-bold text-primary text-center lg:text-2xl font-feature-settings">
              データベース化
            </h3>
            <p className="mt-2 text-sm text-textblack text-center lg:text-base font-feature-settings lg:mt-3">
              自社文書を取り込むだけでナレッジを蓄積できます
            </p>
          </div>

          <div className="relative w-full border border-solid border-gray bg-white px-8 py-6 rounded-lg lg:w-[293px] lg:py-10">
            <h3 className="text-base font-bold text-primary text-center lg:text-2xl font-feature-settings">
              クラウド編集機能
            </h3>
            <p className="mt-2 text-sm text-textblack text-center lg:text-base font-feature-settings lg:mt-3">
              クラウド上で複数人の編集が可能です
            </p>
          </div>

          <div className="relative w-full border border-solid border-gray bg-white px-8 py-6 rounded-lg lg:w-[293px] lg:py-10">
            <h3 className="text-base font-bold text-primary text-center lg:text-2xl font-feature-settings">
              AIレビューアシスト
            </h3>
            <p className="mt-2 text-sm text-textblack text-center lg:text-base font-feature-settings lg:mt-3">
              自社雛形や過去文書との比較をAIがアシストします
            </p>
          </div>

          <div className="relative w-full border border-solid border-gray bg-white px-8 py-6 rounded-lg lg:w-[293px] lg:py-10">
            <h3 className="text-base font-bold text-primary text-center lg:text-2xl font-feature-settings">
              バージョン管理
            </h3>
            <p className="mt-2 text-sm text-textblack text-center lg:text-base font-feature-settings lg:mt-3">
              クラウド上での管理により属人化を防止します
            </p>
          </div>

          <div className="relative w-full border border-solid border-gray bg-white px-8 py-6 rounded-lg lg:w-[293px] lg:py-10">
            <img
              src="/images/img_icon_patent.png"
              className="absolute -top-2 -left-2 h-20 w-[82px] block"
              alt="特許技術"
              width={82}
              height={80}
            />
            <h3 className="text-base font-bold text-primary text-center lg:text-2xl font-feature-settings">
              自動体裁補正
            </h3>
            <p className="mt-2 text-sm text-textblack text-center lg:text-base font-feature-settings lg:mt-3">
              インデントや条番号のずれを自動で補正します
            </p>
          </div>

          <div className="relative w-full border border-solid border-gray bg-white px-8 py-6 rounded-lg lg:w-[293px] lg:py-10">
            <img
              src="/images/img_icon_patent.png"
              className="absolute -top-2 -left-2 h-20 w-[82px] block"
              alt="特許技術"
              width={82}
              height={80}
            />
            <h3 className="text-base font-bold text-primary text-center lg:text-2xl font-feature-settings">
              不足条項サジェスト
            </h3>
            <p className="mt-2 text-sm text-textblack text-center lg:text-base font-feature-settings lg:mt-3">
              不足している条項をAIが
              <br />
              サジェストします
            </p>
          </div>
        </div>

        <Link
          href="/functions/"
          className="mt-8 block transition hover:text-secondary hover:border-secondary base_button linkbutton__medium button__hidden lg:mt-16"
        >
          もっと見る
        </Link>
      </div>
    </section>
  )
}
