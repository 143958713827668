import React from 'react'

const ReasonBox = ({ number, title, description, imageSrc }) => (
  <div className="w-full lg:w-[280px]">
    <div className="flex flex-col-reverse items-center justify-center">
      <div>
        <div className="flex flex-col-reverse items-center">
          <h3 className="text-xl font-bold text-textblack text-center lg:text-2xl font-feature-settings">
            {title}
          </h3>
          <p className="text-4xl text-primary text-center font-robot lg:text-5xl">
            {number}
          </p>
        </div>
        <p className="mt-3 text-sm text-textblack text-center lg:text-base">
          {description}
        </p>
      </div>
      <figure>
        <img decoding="async" src={imageSrc} alt="" />
      </figure>
    </div>
  </div>
)

export const Reason = () => {
  const reasons = [
    {
      number: '01',
      title: (
        <>
          直感的でシンプルな
          <br />
          画面操作
        </>
      ),
      description: (
        <>
          難しい操作はいりません。
          <br />
          LAWGUEではほとんどの作業をクリックひとつで完了できます
        </>
      ),
      imageSrc: '/images/img_contract_top01.svg'
    },
    {
      number: '02',
      title: (
        <>
          様々な業界・事業規模
          <br className="lg:hidden" />
          での導入実績
        </>
      ),
      description:
        '数百社を超える企業、弁護士法人、社会保険労務士事務所、自治体、官公庁等、幅広い導入実績があります',
      imageSrc: '/images/img_contract_top02.svg'
    },
    {
      number: '03',
      title: (
        <>
          万全の
          <br />
          導入サポート
        </>
      ),
      description:
        'システムは入れるだけでは意味がありません。導入から運用開始まで、専任担当者がしっかりサポートします',
      imageSrc: '/images/img_contract_top03.svg'
    }
  ]

  return (
    <section>
      <div className="mx-auto px-6 pt-10 pb-10 text-center lg:pt-40 lg:pb-20 lg:max-w-5xl">
        <h2 className="text-lg font-bold text-textblack text-center lg:text-4xl font-feature-settings">
          LAWGUEが選ばれる理由
        </h2>
        <div className="mt-6 flex flex-wrap justify-between gap-4 lg:gap-10 lg:mt-14">
          {reasons.map((reason, index) => (
            <ReasonBox key={index} {...reason} />
          ))}
        </div>
      </div>
    </section>
  )
}
