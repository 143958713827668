import React from 'react'

const IssueBox = ({ title, description, imageSrc }) => (
  <div className="w-full lg:w-[280px] bg-transparent">
    <div className="flex lg:flex-col-reverse flex-row-reverse justify-start lg:justify-center">
      <div className="w-1/2 lg:w-auto">
        <h3 className="text-left lg:text-center text-base lg:text-2xl text-textblack font-bold fontfeaturesettings">
          {title}
        </h3>
        <p className="text-left lg:text-center text-sm lg:text-base text-textblack fontfeaturesettings mt-2 lg:mt-3">
          {description}
        </p>
      </div>
      <img
        decoding="async"
        src={imageSrc}
        alt=""
        className="w-1/2 lg:w-auto h-full"
      />
    </div>
  </div>
)

export const Issue = () => {
  const issues = [
    {
      title: (
        <>
          気づけば1日中
          <br />
          文書探し
        </>
      ),
      description: '探している文書がいつまでたっても見つからない',
      imageSrc: '/images/img_quest01.png'
    },
    {
      title: (
        <>
          「誰が」「なぜ」が
          <br />
          わからない
        </>
      ),
      description: '作成過程のコメントや修正履歴がブラックボックス化',
      imageSrc: '/images/img_quest05.png'
    },
    {
      title: (
        <>
          ひとつひとつ確認して
          <br />
          体裁を修正
        </>
      ),
      description:
        '完成したと思っても、フォーマット調整が作成以上に時間がかかる',
      imageSrc: '/images/img_quest06.png'
    }
  ]

  return (
    <section>
      <div className="text-center mx-auto w-full px-6 py-10 lg:py-20 max-w-5xl">
        <h2 className="text-lg lg:text-4xl text-textblack font-bold fontfeaturesettings">
          文書業務でこんなことは
          <br className="lg:hidden" />
          ありませんか？
        </h2>
        <div className="flex flex-wrap justify-between mt-6 lg:mt-14 gap-6">
          {issues.map((issue, index) => (
            <IssueBox key={index} {...issue} />
          ))}
        </div>
      </div>
      <div className="img_arrow">
        <img
          decoding="async"
          src="/images/img_sec_arrow.svg"
          alt="Arrow"
          className="w-full"
        />
      </div>
    </section>
  )
}
