import Link from 'next/link'

export function Security() {
  return (
    <section>
      <div className="mx-auto px-6 pt-10 pb-10 text-center lg:pt-40 lg:pb-40 lg:max-w-5xl">
        <h2 className="text-lg font-bold text-textblack text-center lg:text-4xl font-feature-settings">
          セキュリティ
        </h2>

        <p className="mt-6 text-base text-darken text-center font-feature-settings">
          安心してご利用いただくために
        </p>

        <div className="flex md:flex-row flex-col justify-between gap-12">
          <div className="w-full md:max-w-[448px]">
            <div className="flex flex-col-reverse items-center justify-center">
              <img
                src="/images/img_security01-v2.png"
                alt="安心のクラウド基盤"
                width={438}
                height={140}
              />
            </div>
            <h3 className="mt-0 text-base font-bold text-textblack text-center lg:text-2xl font-feature-settings lg:mt-2">
              安心のクラウド基盤
            </h3>
            <p className="mt-2 text-sm text-textblack text-left leading-7 lg:text-base font-feature-settings lg:mt-3">
              AWS上にサービスを構築し、TLS1.2による通信の暗号化、AES-256によるデータ暗号化に加え、各種高水準のセキュリティ対策を施しています。
            </p>
          </div>

          <div className="w-full md:max-w-[448px]">
            <div className="flex flex-col-reverse items-center justify-center">
              <img
                src="/images/img_security02-v2.png"
                alt="第三者機関の診断"
                width={438}
                height={140}
              />
            </div>
            <h3 className="mt-0 text-base font-bold text-textblack text-center lg:text-2xl font-feature-settings lg:mt-2">
              第三者機関の診断
            </h3>
            <p className="mt-2 text-sm text-textblack text-left leading-7 lg:text-base font-feature-settings lg:mt-3">
              国内有数の診断実績を誇る第三者機関の脆弱性診断実施による侵入対策を定期的に実施しています。
            </p>
          </div>
        </div>

        <div className="mx-auto pt-10 lg:pt-14 w-full">
          <h2 className="text-center mb-2 lg:mb-9 text-lg lg:text-3xl text-textblack font-bold fontfeaturesettings">
            情報管理について
          </h2>
          <div className="flex flex-col md:flex-row justify-center items-center md:py-8 md:px-6 md:border-t md:border-b md:border-gray">
            <div className="flex justify-center items-center">
              <img
                decoding="async"
                src="/images/img_security04-v2.png"
                alt="ISMS(ISO27001)取得"
                width="240"
                height="138"
                className="w-8/12 md:w-9/12 lg:w-auto"
              />
            </div>
            <div className="flex flex-col justify-center md:ml-16">
              <h3 className="text-center md:text-left text-base lg:text-2xl text-textblack font-bold fontfeaturesettings">
                ISMS(ISO27001)取得
              </h3>
              <p className="mt-2 text-sm text-textblack text-left leading-7 lg:text-base font-feature-settings lg:mt-3">
                LAWGUEの運営元であるFRAIM株式会社は
                <br className="hidden md:block" />
                情報セキュリティマネジメントシステム(ISMS)の認証を取得しています。
                <br className="hidden md:block lg:hidden" />
                また、プライバシーマークも取得しており、安心の情報管理をしています。
              </p>
            </div>
          </div>
        </div>

        <Link
          href="/security/"
          className="mt-8 block transition hover:text-secondary hover:border-secondary base_button linkbutton__xlarge button__hidden button__nonefull lg:mt-16"
        >
          もっと見る
        </Link>
      </div>
    </section>
  )
}
