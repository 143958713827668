import React from 'react'

const ResolveItem = ({ number, title, description, imageSrc, isReverse }) => (
  <div
    className={`mt-10 flex flex-col lg:flex-row lg:justify-between lg:mt-20 ${
      isReverse ? 'lg:flex-row-reverse' : ''
    }`}
  >
    <div className="w-full lg:w-[47.5%]">
      <div className="flex flex-col-reverse items-start">
        <h3
          className="text-lg font-bold text-textblack text-left lg:text-4xl font-feature-settings"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p className="text-4xl font-bold font-robot leading-none">
          <span className="relative before:absolute before:bg-[#E7DBCC] before:top-1/2 before:left-0 before:w-[110px] before:h-[30px] before:-translate-y-1/2 before:z-[-1] before:mt-[2px]">
            {number.padStart(2, '0')}
          </span>
        </p>
      </div>
      <p
        className="mt-3 text-base text-left leading-relaxed"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
    <div className="mt-4 w-full lg:mt-0 lg:w-[47.5%]">
      <img decoding="async" src={imageSrc} alt="" className="w-full" />
    </div>
  </div>
)

export const Resolve = () => {
  const resolveItems = [
    {
      number: '1',
      title: '今見たい文書を、いますぐ発見',
      description:
        '1年で150時間。これはビジネスパーソンが、<br class="hidden lg:block">「書類探し」に費やしている時間です※。<br class="hidden lg:block">LAWGUEなら、見たいと思った文書を、<br>AIが先回りして発見。しかもその間、たった2秒。<br class="hidden lg:block">貴重な時間をムダにしません。<br><span class="mt-2 text-xs block">※弊社調べ</span>',
      imageSrc: '/images/img_top_task01.png',
      isReverse: false
    },
    {
      number: '2',
      title: '「なんでこうなったんだっけ？」を、まるごと記憶',
      description:
        '文書内のコメントを全部消して、<br class="hidden lg:block">あらたに書いて、メールに添付して･･･<br>そんな面倒から、あなたを解放。 <br>すべてのやりとりがLAWGUE内で完結するので、超高速です。<br>コメント履歴は、まるごと社内の共有ナレッジに。',
      imageSrc: '/images/img_top_task02.png',
      isReverse: true
    },
    {
      number: '3',
      title: '体裁を直すスピードは、<br>ヒトの10倍',
      description:
        'ずれた番号やレイアウトを、一つひとつ手で直す。<br>そんな作業に、どれだけ時間がかかっていたでしょう。<br>LAWGUEなら、ワンクリックで修正完了。 <br>文書が長ければ長いほど、圧倒的な時短になります。',
      imageSrc: '/images/img_top_task03.png',
      isReverse: false
    }
  ]

  return (
    <section>
      <div className="mx-auto w-full px-6 pt-0 pb-16 lg:max-w-[1120px] lg:pt-10 lg:pb-28">
        <div className="relative">
          <h2 className="py-10 text-lg font-bold text-center lg:text-4xl lg:py-20 title_pickup">
            LAWGUEならこれらの課題を
            <br className="lg:hidden" />
            解決できます
          </h2>
          <span className="img_title_pickup">
            <img
              decoding="async"
              src="/images/img_title_pickup.svg"
              alt=""
              className="w-full"
            />
          </span>
        </div>
        {resolveItems.map((item, index) => (
          <ResolveItem key={index} {...item} />
        ))}
      </div>
    </section>
  )
}
