'use client'

import React, { useEffect } from 'react'
import useAccordion from '@/hooks/useAccordion'

import { Request } from '@/app/components/templates/non-block/Request'
import { Functions } from '@/app/components/templates/non-block/Functions'
import { Security } from '@/app/components/templates/non-block/Security'
import { Question } from '@/app/components/templates/non-block/Question'
import { Contact } from '@/components/parts/contact'
import { Logo } from '@/app/components/templates/non-block/Logo'
import Link from 'next/link'
import { Reason } from '@/app/components/templates/non-block/Reason'
import { Solution } from '@/app/components/templates/non-block/Solution'
import { Resolve } from '@/app/components/templates/non-block/Resolve'
import { Issue } from '@/app/components/templates/non-block/Issue'
import { Video } from '@/app/components/templates/non-block/Video'
import Visual from '@/app/components/templates/non-block/Visual'
import { NewsList } from '@/app/components/newt/templates/NewsList'
import { VoiceList } from '@/app/components/newt/templates/VoiceList'

export default function Top({ news, logo, voice, logos }) {
  useAccordion()
  useEffect(() => {}, [])

  return (
    <>
      <Visual logos={logos} />
      <Video />
      <Issue />
      <Resolve />
      <Request position={'resolve'} />
      <Solution />
      <Reason />
      <section className="mx-auto px-6 pt-10 pb-10 text-center lg:pt-40 lg:pb-40 lg:max-w-5xl">
        <h2 className="text-lg font-bold text-textblack text-center lg:text-4xl font-feature-settings">
          導入事例
        </h2>
        <ul className="mt-6 flex flex-col gap-y-[30px] text-left lg:grid lg:grid-cols-3 lg:gap-x-[60px] lg:mt-10">
          {voice.map((voiceItem) => (
            <VoiceList key={voiceItem._id} voice={voiceItem} />
          ))}
        </ul>
        <Link
          href="/voice"
          className="mt-8 block transition hover:text-secondary hover:border-secondary base_button linkbutton__medium button__hidden lg:mt-16"
        >
          もっと見る
        </Link>
      </section>

      <section className="w-full px-6 pt-10 pb-20 text-center bg-white lg:pt-40 lg:pb-44">
        <h2 className="text-base font-bold text-textblack text-center md:text-2xl">
          他にも、数多くの企業様にご利用いただいています
        </h2>
        <div className="mt-8 lg:mt-16">
          <div className="mx-auto max-w-[1176px] px-1 flex flex-wrap items-center justify-start gap-x-[3.9%] gap-y-10 lg:px-12 lg:gap-y-[42px]">
            {logo.map((logoItem) => (
              <Logo key={logoItem._id} logo={logoItem} />
            ))}
          </div>
        </div>
      </section>

      <Request position={'voice'} />
      <Functions />
      <Security />
      <Question />

      <section className="mx-auto px-6 pt-10 pb-10 text-center lg:pt-40 lg:pb-40 lg:max-w-5xl">
        <h2 className="text-lg font-bold text-textblack text-center lg:text-4xl font-feature-settings">
          お知らせ
        </h2>
        <div className="mt-4 lg:mt-12">
          {news.map((newsItem) => (
            <NewsList key={newsItem._id} news={newsItem} />
          ))}
        </div>
      </section>

      <Contact bg="bg-lawguebase" />
    </>
  )
}
