import Link from 'next/link'
import React from 'react'

const SolutionBox = ({
  imageSrc,
  title,
  description,
  link,
  isExternal = false
}) => (
  <div className="relative w-full border border-solid border-gray bg-white px-8 py-6 rounded-lg lg:w-[293px] lg:py-10">
    <div className="flex flex-col-reverse items-center justify-center">
      <div></div>
      <img decoding="async" src={imageSrc} alt="" className="h-full" />
    </div>

    <h3 className="mt-0 text-lg font-bold text-primary text-center font-feature-settings lg:mt-2">
      {title}
    </h3>

    <p className="mt-3 text-sm text-textblack text-center lg:text-base font-feature-settings">
      {description}
    </p>

    {isExternal ? (
      <p className="mt-4 text-center">
        <Link
          href={link}
          target="_blank"
          rel="noreferrer noopener"
          className="pagescroll base_button_d1link button__hidden transition hover:text-secondary hover:border-secondary"
        >
          詳しく見る
        </Link>
      </p>
    ) : (
      <Link
        href={link}
        className="mt-4 block text-center transition hover:text-secondary hover:border-secondary base_button linkbutton__large button__hidden"
      >
        詳しく見る
      </Link>
    )}
  </div>
)

export const Solution = () => {
  const solutions = [
    {
      imageSrc: '/images/img_solution01.png',
      title: '契約ナレッジマネジメント',
      description: (
        <>
          契約書業務の時間を大幅に
          <br />
          削減します
        </>
      ),
      link: '/contract'
    },
    {
      imageSrc: '/images/img_solution02.png',
      title: '規程・マニュアル管理',
      description: (
        <>
          規程管理をクラウドで
          <br />
          効率化できます
        </>
      ),
      link: '/regulation'
    },
    {
      imageSrc: '/images/img_solution03.png',
      title: '法令改正対応',
      description: (
        <>
          最新の法改正情報を受け取り
          <br />
          法改正に迅速に対応します
        </>
      ),
      link: '/law'
    },
    {
      imageSrc: '/images/img_solution04.png',
      title: '英文契約書レビュー',
      description: '対訳辞書登録で正確な翻訳を実現します',
      link: '/translation'
    },
    {
      imageSrc: '/images/img_solution05.png',
      title: 'D1-LAWGUE',
      description: '次世代クラウドエディタで行政文書のDXを推進します',
      link: 'https://d1.lawgue.com/',
      isExternal: true
    }
  ]

  return (
    <section>
      <div className="mx-auto px-6 pt-10 pb-10 text-center lg:pt-40 lg:pb-20 lg:max-w-5xl">
        <h2 className="text-lg font-bold text-textblack text-center lg:text-4xl font-feature-settings">
          ソリューション
        </h2>

        <p className="mt-6 text-base text-darken text-center font-feature-settings leading-7">
          LAWGUEを使用するとドキュメントを数分で作成し、驚くほど仕事を効率化することができます。
          <br />
          便利な通知やコラボレーション、改善のヒントとともに、様々な場面で活躍します。
        </p>

        <div className="mt-6 flex flex-wrap justify-center gap-4 lg:gap-10 lg:mt-14">
          {solutions.map((solution, index) => (
            <SolutionBox key={index} {...solution} />
          ))}
        </div>
      </div>
    </section>
  )
}
